







































import {Component, Vue, Inject, Prop, Watch} from 'vue-property-decorator';
import Util from '../../../../lib/util'
import AbpBase from '../../../../lib/abpbase'
import TradeTransportLocation from '../../../../store/entities/countrycontext/tradetransportlocation'

@Component
export default class CreateTradeTransportLocation extends AbpBase {
    private subdivisionList: any;
    private countryList: any;

    @Prop({type: Boolean, default: false}) value: boolean;
    @Prop({type: Boolean, default: false}) public toEdit: boolean;
    tradeTransportLocation: TradeTransportLocation = new TradeTransportLocation();

    save() {
        (this.$refs.tradeTransportLocationForm as any).validate(async (valid: boolean) => {
            if (valid) {
                await this.$store.dispatch({
                    type: this.toEdit ? 'tradetransportlocation/update' : 'tradetransportlocation/create',
                    data: this.tradeTransportLocation
                });
                (this.$refs.tradeTransportLocationForm as any).resetFields();
                this.$emit('save-success');
                this.$emit('input', false);
            }
        })
    }

    async getSubdivisions(countryId: number) {
        if (countryId != null) {
            this.subdivisionList = await this.$store.dispatch({
                type: 'subdivision/byCountryId',
                data: countryId
            })
        }
    }

    async isCountryChange(val: number) {
        await this.getSubdivisions(val);
        (this.$refs.subdivision as any).clearSingleSelect();
    }

    cancel() {
        (this.$refs.tradeTransportLocationForm as any).resetFields();
        this.$emit('input', false);
    }

    async visibleChange(value: boolean) {
        if (!value) {
            this.$emit('input', value);
        } else {
            if (this.toEdit) {
                this.tradeTransportLocation = Util.extend(true, {}, this.$store.state.tradetransportlocation.editTradeTransportLocation);
                await this.getSubdivisions(this.tradeTransportLocation.countryId);
            } else {
                this.tradeTransportLocation = new TradeTransportLocation();
            }

        }
    }

    data() {
        return {
            subdivisionList: [],
            countryList: [],
            updateCount: 0,
        }
    };

    async mounted() {
        this.countryList = await this.$store.dispatch('country/lookUp');
    }

    checkCountryInput(rule, value, callback) {
        if (value == null) {
            return callback(new Error(this.L('FieldIsRequired', undefined, this.L('Country'))));
        }
        return callback();
    }

    tradeTransportLocationRule = {
        name: [{required: true, message: this.L('FieldIsRequired', undefined, this.L('Name')), trigger: 'blur'}],
        functions: [{
            required: true,
            message: this.L('FieldIsRequired', undefined, this.L('Functions')),
            trigger: 'blur'
        }],
        code: [{required: true, message: this.L('FieldIsRequired', undefined, this.L('Code')), trigger: 'blur'}],
        countryId: [{
            required: true,
            validator: this.checkCountryInput,
            trigger: 'change'
        }]
    }
}
