



























































import {Component, Vue, Inject, Prop, Watch} from 'vue-property-decorator';
import Util from '@/lib/util'
import AbpBase from '@/lib/abpbase'
import PageRequest from '@/store/entities/page-request'
import TradeTransportLocationForm from './tradetransportlocation-form.vue'
import SortingModel from "@/store/entities/sortingmodel";
import TradeTransportLocationDto from "@/store/entities/countrycontext/tradetransportlocation";

class PageTradeTransportLocationRequest extends PageRequest {
    countryId: number | null;
}

@Component({
    components: {TradeTransportLocationForm}
})

export default class TradeTransportLocation extends AbpBase {
    private countryList: any;

    edit() {
        this.editModalShow = true;
    }

    async changeSort(data) {
        if (this.pagerequest.sorting == null) {
            this.pagerequest.sorting = [];
        }
        let item = this.pagerequest.sorting.find(x => x.fieldName === data.key)
        if (item == null) {
            let sortingModel = new SortingModel();
            sortingModel.fieldName = data.key;
            sortingModel.isDescendingDirection = data.order === "desc";
            this.pagerequest.sorting.push(sortingModel)
        } else {
            item.isDescendingDirection = data.order === "desc";
        }
        await this.getpage()
    }

    pagerequest: PageTradeTransportLocationRequest = new PageTradeTransportLocationRequest();

    createModalShow: boolean = false;
    editModalShow: boolean = false;

    data() {
        return {
            countryList: [],
            updateCount: 0,
        }
    };

    async handleUpload(file) {
        let formData = new FormData();
        formData.append('file', file);
        await this.$store.dispatch({
            type: 'tradetransportlocation/uploadFile',
            data: formData
        })
        await this.getpage();
    }

    async mounted() {
        this.countryList = await this.$store.dispatch('country/lookUp');
    }

    get list() {
        return this.$store.state.tradetransportlocation.list;
    };

    get loading() {
        return this.$store.state.tradetransportlocation.loading;
    }

    create() {
        this.$store.commit('tradetransportlocation/edit', new TradeTransportLocationDto())
        this.createModalShow = true;
    }

    isCountryChange(val: number) {

        if (val === 0)
            this.pagerequest.countryId = null;
        else
            this.pagerequest.countryId = val;
    }

    pageChange(page: number) {
        this.$store.commit('tradetransportlocation/setCurrentPage', page);
        this.getpage();
    }

    pagesizeChange(pagesize: number) {
        this.$store.commit('tradetransportlocation/setPageSize', pagesize);
        this.getpage();
    }

    async search() {
        this.$store.commit('tradetransportlocation/setCurrentPage', 1);
        await this.getpage();
    }

    async getpage() {

        this.pagerequest.maxResultCount = this.pageSize;
        this.pagerequest.skipCount = (this.currentPage - 1) * this.pageSize;

        await this.$store.dispatch({
            type: 'tradetransportlocation/getAll',
            data: this.pagerequest
        })
    }

    get pageSize() {
        return this.$store.state.tradetransportlocation.pageSize;
    }

    get totalCount() {
        return this.$store.state.tradetransportlocation.totalCount;
    }

    get currentPage() {
        return this.$store.state.tradetransportlocation.currentPage;
    }

    columns = [
        {
            title: this.L('UN/LOCODE'),
            key: 'unloCode',
            sortable: 'custom'
        }, {
            title: this.L('Name'),
            key: 'name',
            sortable: 'custom'
        }, {
            title: this.L('Functions'),
            key: 'functions',
            sortable: 'custom'
        }, {
            title: this.L('Code'),
            key: 'code',
            sortable: 'custom'
        }, {
            title: this.L('Country'),
            key: 'countryName',
            sortable: 'custom'
        }, {
            title: this.L('Post code'),
            key: 'postCode',
            sortable: 'custom'
        }, {
            title: this.L('Actions'),
            key: 'Actions',
            width: 150,
            render: (h: any, params: any) => {
                return h('div', [
                    h('Button', {
                        props: {
                            type: 'primary',
                            size: 'small',
                            disabled: !this.hasEditPermission()
                        },
                        style: {
                            marginRight: '5px'
                        },
                        on: {
                            click: async () => {
                                this.$store.commit('tradetransportlocation/edit', await this.$store.dispatch({
                                    type: 'tradetransportlocation/get',
                                    data: params.row.id
                                }));
                                this.edit();
                            }
                        }
                    }, this.L('Edit')),
                    h('Button', {
                        props: {
                            type: 'error',
                            size: 'small',
                            disabled: !this.hasDeletePermission()
                        },
                        on: {
                            click: async () => {
                                this.$Modal.confirm({
                                    title: this.L('Tips'),
                                    content: this.L('Delete UN/LOCODE Confirm'),
                                    okText: this.L('Yes'),
                                    cancelText: this.L('No'),
                                    onOk: async () => {
                                        await this.$store.dispatch({
                                            type: 'tradetransportlocation/delete',
                                            data: params.row
                                        })
                                        await this.getpage();
                                    }
                                })
                            }
                        }
                    }, this.L('Delete'))
                ])
            }
        }]

    async created() {
        await this.getpage();
    }

    hasAddPermission(){
        return this.hasPermission("Pages.TradeTransportLocations.Add");
    }

    hasEditPermission(){
        return this.hasPermission("Pages.TradeTransportLocations.Edit");
    }

    hasDeletePermission(){
        return this.hasPermission("Pages.TradeTransportLocations.Delete");
    }

}
